import React from "react"
import Layout from '../components/Layout'
import { Link } from "gatsby"
import * as styles from '../styles/home.module.css'
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet='urtf-8'/>
        <title>Venture Games - Home</title>
      </Helmet>

      <section className={styles.gamePreviewBanner}>
        <img src="/ShooterFeatureBanner.png" alt=""/>
        <div>
          <p>Blast. Upgrade. Survive. How long can you last?</p>
          <Link className={styles.previewBtn} to="/games/shooter-space-age">PLAY FOR FREE</Link>
        </div>
      </section>
    </Layout>
    )
}
